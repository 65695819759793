html {
    height: 100%;
}

body {
    height: 100%;
    color: #3d464d;
    fill: currentColor;
    font-family: 'Roboto', sans-serif;
    backface-visibility: hidden;
}


.header {
    display: flex;
    padding: 60px 0;
    min-height: calc(100% - 200px);

    @media (max-width: 575px) {
        padding: 20px 0;
    }
}
.header__row {
    display: flex;
    margin: auto;

    @media (max-width: 991px) {
        display: block;
    }
    @media (max-width: 379px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.header__card,
.header__window {
    width: 350px;
    display: flex;
    flex-shrink: 0;
}
.header__window {
    margin-left: 70px;

    @media (max-width: 991px) {
        margin-top: 30px;
        margin-left: 0;
        height: 530px;
    }
}
.header__card,
.header__window {
    @media (max-width: 379px) {
        width: 100%;
    }
}



.card {
    background: #ffd333;
    border: none;
    border-radius: 2.5px;
    padding: 30px;
}
.card__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px 0 0;

    @media (max-width: 991px) {
        padding-bottom: 10px;
    }
}
.card__logo a {
    color: inherit;

    svg {
        fill: currentColor;
    }
}
.card__subtitle {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
}
.card__divider {
    margin: 42px auto;

    &,
    &::before,
    &::after {
        position: relative;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: rgba(#000, .15);
    }
    &::before,
    &::after {
        position: absolute;
        content: '';
        display: block;
    }
    &::before {
        left: -30px
    }
    &::after {
        left: 30px
    }

    @media (max-width: 991px) {
        display: none;
    }
}
.card__features {
    padding: 0 10px 8px;
    margin: 0;
    list-style: none;
    line-height: 1.25;

    li {
        position: relative;
        padding-left: 15px;

        &::before {
            left: 0;
            top: 7px;
            position: absolute;
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 2.5px;
            background: currentColor;
        }
    }
    li + li {
        margin-top: 7px;
    }

    @media (max-width: 991px) {
        display: none;
    }
}



.window {
    background: #f0f0f0;
    border: none;
    border-radius: 2.5px;
    display: flex;
    flex-direction: column;
    transition: all .4s;

    &:focus {
        outline: none;
    }
}
.window__dots {
    margin: 5px 4px;
    background: #ffa6a6;
    box-shadow: 0 0 0 1px rgba(#000, .07) inset;

    &,
    &::before,
    &::after {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
    }
    &::before,
    &::after {
        position: absolute;
        content: '';
        display: block;
    }
    &::before {
        left: 14px;
        background: #ffe566;
        box-shadow: 0 0 0 1px rgba(#000, .06) inset;
    }
    &::after {
        left: 28px;
        background: #a1e57e;
        box-shadow: 0 0 0 1px rgba(#000, .06) inset;
    }
}
.window__content {
    position: relative;
    background: #fff;
    margin: 0 3px 3px;
    border-radius: 1.5px;
    flex-grow: 1;
    overflow: hidden;
}
.window__content img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}
.window__versions {
    $position: 20px;

    position: absolute;
    display: flex;
    bottom: $position;
    left: $position;
    right: $position;

    a {
        background-color: #3d464d;
        color: #fff;
        width: 50%;
        border-radius: 1.5px;
        padding: 10px 10px;
        text-align: center;
        font-weight: 500;
        letter-spacing: .08em;
        transition: background .2s;

        &:hover {
            background-color: lighten(#3d464d, 10%);
            text-decoration: none;
        }
    }
    a + a {
        margin-left: $position;
    }
}
.window:hover,
.window:focus {
    background: #fff;
    box-shadow: 0 1px 20px rgba(#000, .15);
}
.window--default {
    height: 380px;

    .window__content img {
        transform: translateX(calc(-50% - .5px));
    }
    .window__versions {
        $position: 14px;

        bottom: $position;
        left: $position;
        right: $position;

        a {
            font-size: 14px;
            padding: 7px 10px;
        }

        a + a {
            margin-left: $position;
        }
    }
}



.section {
    margin-top: 30px;
}
.section__header {
    margin-bottom: 42px;
}
.section__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -15px;
}
.section__item {
    width: 255px;
    margin: 15px;
    flex-shrink: 0;
}




.section-header {
    display: flex;
    align-items: center;
}
.section-header__title {
    line-height: 1;
    margin: 0 12px;
    font-size: 22px;
    font-weight: 700;
}
.section-header__divider {
    flex-grow: 1;
    height: 2px;
    background: #ebebeb;
}



.copyright {
    margin-top: 90px;
    text-align: center;
    height: 100px;
    font-size: 14px;
    line-height: 100px;
    letter-spacing: .02em;
    color: #999;

    a {
        color: #3d464d;
    }
    a:hover {
        color: #1a66ff;
    }
    a, a:hover {
        text-decoration: none;
    }
}
